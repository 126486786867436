












import Vue from "vue";

export default Vue.extend({
  name: "Logout",
  methods: {
    logout(): void {
      this.$router.push("/auth/logout");
    }
  }
});
