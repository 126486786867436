














































import Vue from "vue";
import {format} from "@/util/bedrag";

export default Vue.extend({
  name: "BestellingSamenvatting",
  props: {
    bestellingLaden: Boolean,
    totaal: Number,
    plaatsBestelling: Function,
    annuleer: Function,
    saldo: Number,
  },
  methods: {
    format
  }
});
