








import Vue from "vue";

export default Vue.extend({
  name: "Logout",
  created(): void {
    this.$store.commit("setToken", null);
    this.$router.push("/");
  }
});
