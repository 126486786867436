

























import {BarLocatie} from "@/model";
import Vue from "vue";

export default Vue.extend({
  name: "Login",
  data: () => ({
    naam: ""
  }),
  computed: {
    profielNaam(): string {
      return this.$store.state.user.profiel?.displayName ?? "";
    },
    vertrouwd(): BarLocatie | null {
      return this.$store.state.user.locatieToken;
    },
    loggedIn() {
      return !!this.$store.state.user.tokenData;
    }
  }
});
