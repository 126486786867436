











import Vue from "vue";
import SimpleKeyboard from "@/components/SimpleKeyboard.vue";

export default Vue.extend({
  name: "Keyboard",
  model: {
    prop: "modelValue",
    event: "update:modelValue"
  },
  components: { SimpleKeyboard },
  props: {
    modelValue: {
      type: String
    },
    placeholder: {
      default: "",
      type: String
    },
    grabFocus: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    emitValue(value: string) {
      let val = value;
      if (val) {
        val = val?.toUpperCase();
      }
      this.$emit("update:modelValue", val);
    }
  }
});
