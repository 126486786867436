


























import {Persoon} from "@/model";
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    persoon: null as Persoon | null,
    bijnaam: "",
    laden: false
  }),
  computed: {
    personen(): Persoon[] {
      return this.$store.getters.personenWeergave;
    }
  },
  methods: {
    selecteerPersoon(uid: string) {
      if (!uid) {
        this.persoon = null;
        this.bijnaam = "";
      } else {
        this.persoon = this.$store.state.persoon.personen[uid];
        this.bijnaam = this.persoon?.naam ?? "";
      }
    },
    async opslaan() {
      if (!this.persoon) {
        throw new Error("Persoon niet geselecteerd");
      }

      this.laden = true;

      await this.$store.dispatch("updateBijnaam", {
        id: this.persoon.uid,
        name: this.bijnaam
      });

      this.laden = false;
    }
  }
});
