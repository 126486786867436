var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-switch',{attrs:{"label":"Alleen geselecteerde persoon"},model:{value:(_vm.zoekInAlles),callback:function ($$v) {_vm.zoekInAlles=$$v},expression:"zoekInAlles"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"datumMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Datum","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.datumText),callback:function ($$v) {_vm.datumText=$$v},expression:"datumText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datumMenu),callback:function ($$v) {_vm.datumMenu=$$v},expression:"datumMenu"}},[_c('v-date-picker',{attrs:{"range":"","min":"1950-01-01"},model:{value:(_vm.datum),callback:function ($$v) {_vm.datum=$$v},expression:"datum"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"title":"Filter op specifieke producten","type":_vm.isIndeterminate ? 'primary' : 'default'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune")]),_vm._v(" Producten ")],1)]}}]),model:{value:(_vm.productSelectieZichtbaar),callback:function ($$v) {_vm.productSelectieZichtbaar=$$v},expression:"productSelectieZichtbaar"}},[_c('v-card',[_c('v-card-title',[_vm._v("Selecteer producten")]),_c('v-card-text',[_c('v-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate,"label":"Alle"},on:{"change":_vm.handleCheckAllChange},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v("Alle ")]),_c('v-divider'),_c('v-row',_vm._l((_vm.producten),function(product){return _c('v-col',{key:product.id,attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"value":product.id,"label":product.beschrijving},model:{value:(_vm.selectedProducten),callback:function ($$v) {_vm.selectedProducten=$$v},expression:"selectedProducten"}})],1)}),1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"size":"medium"},on:{"click":_vm.zoeken}},[_vm._v("Zoeken")])],1),_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.bestellingen,"headers":_vm.headers,"sort-by":"moment","sort-desc":""},scopedSlots:_vm._u([{key:"item.uid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.naamFormatter(item))+" ")]}},{key:"item.inhoud",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((item.inhoud),function(el){return _c('li',{key:el.id},[_vm._v(" "+_vm._s(el.aantal)+" "+_vm._s(el.product.beschrijving)+" ")])}),0)]}},{key:"item.totaal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(item.totaal))+" ")]}},{key:"item.moment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.moment).toLocaleString('nl'))+" ")]}},{key:"item.opties",fn:function(ref){
var item = ref.item;
return [(!item.deleted)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!item.deleted)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.handleVerwijder(item)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(item.deleted)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.handleHerstel(item)}}},[_vm._v("mdi-restore ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }