
















































































import Vue from "vue";
import {BarLocatie, Rechten} from "@/model";
import Clock from "./components/Clock.vue";

export default Vue.extend({
  components: {Clock},
  name: "App",
  data: () => ({
    loading: true,
    drawer: false,
    msg: "",
    loadingProgress: 0,
  }),
  computed: {
    rechten(): Rechten {
      return this.$store.getters.rechten;
    },
    loggedIn(): boolean {
      return Boolean(this.$store.getters.token);
    },
    bestellingUrl(): string | null {
      const {selectie} = this.$store.state.user;
      if (selectie) {
        return `/invoer/${selectie}`;
      }
      return null;
    },
    vertrouwd(): BarLocatie | null {
      return this.$store.state.user.locatieToken;
    }
  },
  watch: {
    async loggedIn() {
      await this.processLogin()
    }
  },
  methods: {
    async setLoading(progress: number, msg: string): Promise<void> {
      this.loading = false;
      this.msg = msg;
      this.loadingProgress = progress;
      // NextTick om er voor te zorgen dat loading update
      await this.$nextTick();
      this.loading = true;
    },
    async processLogin() {
      if (this.loggedIn) {
        try {
          await this.setLoading(25, "Profiel laden...");
          await this.$store.dispatch('fetchProfiel');
          await this.setLoading(50, "Leden laden...");
          await this.$store.dispatch('listUsers');
          await this.setLoading(75, "Producten laden...");
          await this.$store.dispatch('listProducten');
        } finally {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    }
  },
  async created() {
    await this.processLogin()
  }
});
