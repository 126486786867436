































import {BarLocatie} from "@/model";
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    naam: "",
    laden: false
  }),
  computed: {
    vertrouwd(): BarLocatie | null {
      return this.$store.state.user.locatieToken;
    }
  },
  methods: {
    async vertrouw() {
      this.laden = true;
      await this.$store.dispatch("vertrouwLocatie", this.naam);
      this.laden = false;
    },
    async stopVertrouwen() {
      this.$store.commit("setLocatieToken", null);
    }
  }
});
