







import Vue from "vue";
import Keyboard from "@/components/Keyboard.vue";
import LedenTable from "@/components/LedenTable.vue";

export default Vue.extend({
  name: "Personen",
  components: {
    LedenTable,
    Keyboard
  },
  data: () => ({
    value: ""
  }),
  computed: {
    upperValue(): string {
      return this.value.toUpperCase();
    }
  },
  methods: {
    change(value: string) {
      this.value = value;
    }
  }
});
