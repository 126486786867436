











import Vue, {PropType} from 'vue';
import {Product} from '@/model';
import {format} from "@/util/bedrag";

export default Vue.extend({
  name: 'ProductWeergave',
  props: {
    producten: {
      type: Array as PropType<Product[]>,
      default: () => [],
    },
  },
  methods: {
    format,
  },
});
