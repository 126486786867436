











import Vue from "vue";
import SimpleKeyboard from "@/components/SimpleKeyboard.vue";

export default Vue.extend({
  name: "Numpad",
  components: {SimpleKeyboard},
  model: {
    prop: "modelValue",
    event: "update:modelValue"
  },
  props: {
    modelValue: String,
    defaultValue: {
      default: "",
      type: String
    }
  }
});
