














import Vue from "vue";
import BeheerBijnaam from "../components/beheer/BeheerBijnaam.vue";
import BeheerVertrouw from "../components/beheer/BeheerVertrouw.vue";
import {Rechten} from "@/model";

export default Vue.extend({
  components: { BeheerBijnaam, BeheerVertrouw },
  computed: {
    rechten(): Rechten {
      return this.$store.getters.rechten;
    },
  }
});
