




































































import Vue from "vue";
import {Bestelling, BestellingInhoud, Persoon, Product} from "@/model";
import Numpad from "../components/Numpad.vue";
import {SaldoError} from "@/util/util";
import ProductWeergave from "../components/bestellingen/ProductWeergave.vue";
import BestellingSamenvatting from "../components/bestellingen/BestellingSamenvatting.vue";
import {sum} from "@/util/list";

export default Vue.extend({
  name: "Bestelling",
  components: {
    BestellingSamenvatting,
    ProductWeergave,
    Numpad
  },
  props: {
    uid: String,
    bestellingId: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    aantal: "",
    bestellingLaden: false,
    forceBestelling: false,
    notificatie: "",
    notificatieWeergeven: false
  }),
  created() {
    this.$store.commit("setSelectie", this.uid);

    if (this.bestellingId) {
      const oudeBestelling = this.$store.state.bestelling.bestellingen[this.bestellingId]

      this.$store.commit("setInvoer", oudeBestelling.inhoud);
      this.$store.commit("setOudeInvoer", oudeBestelling);
    } else {
      this.$store.commit("setInvoer", {});
    }
  },
  computed: {
    producten(): Product[] {
      return this.$store.getters.zichtbareProducten;
    },
    saldo(): number {
      const saldo = this.persoon.saldo;
      if (this.oudeBestellingInhoud) {
        return (
            saldo +
            sum(
                ...this.oudeBestellingInhoud.inhoud.map(
                    b => b.product.prijs * b.aantal
                )
            )
        );
      }

      return saldo;
    },
    persoon(): Persoon {
      return this.$store.state.persoon.personen[this.uid];
    },
    totaal(): number {
      const inhoud = Object.values(this.bestellingInhoud);
      return sum(...inhoud.map(b => b.product.prijs * b.aantal));
    },
    bestellingInhoud(): Record<string, BestellingInhoud> {
      return this.$store.state.invoer.inhoud;
    },
    oudeBestellingInhoud(): Bestelling | null {
      return this.$store.state.invoer.oudeBestelling;
    }
  },
  methods: {
    verwijderInvoer(id: number): void {
      this.$store.commit("verwijderInvoer", id);
    },
    selecteerInvoer(product: Product): void {
      this.$store.commit("selecteerInvoer", {
        product,
        aantal: this.aantal
      });

      this.aantal = "";
    },
    async bestel(force: boolean): Promise<void> {
      this.bestellingLaden = true;

      await this.$store.dispatch("plaatsBestelling", {
        inhoud: this.bestellingInhoud,
        persoon: this.persoon,
        force
      });

      this.bestellingLaden = false;

      await this.$router.replace("/personen");
    },
    async plaatsBestelling(): Promise<void> {
      try {
        await this.bestel(false);
      } catch (e) {
        if (e instanceof SaldoError) {
          try {
            await this.$notify.confirm(
                {text: e.message},
                {yesText: "Doorgaan", noText: "Terug"}
            );

            await this.bestel(true);
          } catch (e) {
            // confirm geannuleerd
          }
        } else {
          this.$notify.show({text: e.message});
        }
      } finally {
        this.bestellingLaden = false;
      }
    },
    annuleer(): void {
      this.$store.commit("clearInvoer");
      this.aantal = "";
      this.$store.commit("setSelectie", null);
      this.$router.replace("/personen");
    }
  }
});
