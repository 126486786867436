





















































































































import Vue from "vue";
import {Bestelling, Persoon, Product} from "@/model";
import {format} from "@/util/bedrag";

export default Vue.extend({
  name: "Bestellingen",
  computed: {
    bestellingen(): Bestelling[] {
      return Object.values(this.$store.state.bestelling.bestellingen);
    },
    producten(): Product[] {
      return Object.values<Product>(this.$store.state.product.producten).filter(
          p => !p.beheer && p.status === 1
      );
    },
    headers() {
      return [
        {
          text: "Naam",
          value: "uid"
        },
        {text: "Datum en tijd", value: "moment"},
        {text: "Totaal", value: "totaal"},
        {text: "Bestelling", value: "inhoud"},
        {text: "Opties", value: "opties", sortable: false}
      ];
    },
    isIndeterminate(): boolean {
      return (
          this.selectedProducten.length > 0 &&
          this.selectedProducten.length < this.producten.length
      );
    },
    datumText() {
      return this.datum.join(" ~ ");
    }
  },
  data: () => ({
    verwijderLaden: {} as Record<string, boolean>,
    herstelLaden: {} as Record<string, boolean>,
    productSelectieZichtbaar: false,
    zoekInAlles: true,
    datum: [
      new Date(+new Date() - 3600 * 1000 * 24 * 15).toISOString().substr(0, 10),
      new Date().toISOString().substr(0, 10)
    ] as string[],
    datumMenu: false,
    selectedProducten: [] as string[],
    // isIndeterminate: false,
    checkAll: false,
    shortcuts: [
      {
        text: "Afgelopen week",
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        })()
      },
      {
        text: "Laatste 2 weken",
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
          return [start, end];
        })()
      },
      {
        text: "Afgelopen maand",
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        })()
      },
      {
        text: "Laatste 3 maanden",
        value: (() => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        })()
      }
    ]
  }),
  methods: {
    naamFormatter(row: Bestelling): string {
      return this.getPersoon(row.uid)?.weergave;
    },
    getPersoon(uid: string): Persoon {
      return this.$store.state.persoon.personen[uid];
    },
    getProduct(id: string): Product {
      return this.$store.state.product.producten[id];
    },
    getBestelLijstString(bestelLijst: Record<string, string>): string[] {
      return Object.entries(bestelLijst).map(
          ([id, aantal]) =>
              `${aantal} ${this.getProduct(id)?.beschrijving}`
      );
    },
    format,
    handleCheckedProductenChange(value: string[]) {
      this.checkAll = value.length === this.producten.length;
    },
    handleCheckAllChange(val: boolean) {
      this.selectedProducten = val ? this.producten.map(v => v.id) : [];
    },
    handleEdit(row: Bestelling) {
      this.$router.push(`/invoer/${row.uid}/bewerken/${row.id}`);
    },
    async handleVerwijder(row: Bestelling) {
      this.verwijderLaden[row.id] = true;
      try {
        await this.$store.dispatch("verwijderBestelling", row);
      } catch (e) {
        //this.$message.error(e.message);
        // TODO
      }
      delete this.verwijderLaden[row.id];
    },
    async handleHerstel(row: Bestelling) {
      this.herstelLaden[row.id] = true;
      try {
        await this.$store.dispatch("herstelBestelling", row);
      } catch (e) {
        //this.$message.error(e.message);
        // TODO
      }
      delete this.verwijderLaden[row.id];
    },
    zoeken() {
      this.$store.dispatch("fetchBestellingen", {
        aantal: this.zoekInAlles ? "alles" : this.$store.state.user.selectie,
        begin: this.datum[0],
        eind: this.datum[1],
        productType: this.selectedProducten
      });
    }
  },
  created() {
    setTimeout(() => {
      this.$store.dispatch("fetchBestellingen", {
        aantal: "alles",
        begin: "",
        eind: "",
        productType: []
      });
    }, 1000);
  }
});
