




















import Vue from "vue"
const defaultOptions: ToastOptions = {
  x: "left",
  y: "bottom",
  timeout: 3000,
  color: "default"
};

export interface ToastOptions {
  x?: "left" | "right" | "centered";
  y?: "bottom" | "top";
  timeout?: number;
  color?: string;
}

export default Vue.extend({
  data() {
    return {
      model: false,
      promise: null as {
        // eslint-disable-next-line no-unused-vars
        resolve: (value: unknown | PromiseLike<unknown>) => void;
        // eslint-disable-next-line no-unused-vars
        reject: (reason?: any) => void;
      } | null,
      text: {},
      options: {} as ToastOptions
    };
  },
  methods: {
    show(text: string, options: ToastOptions) {
      return new Promise((resolve, reject) => {
        if (this.model) this.model = false;
        this.$nextTick(() => {
          this.promise = { resolve, reject };
          this.text = text;
          this.options = { ...defaultOptions, ...options };
          this.model = true;
        });
      });
    },
    close() {
      this.promise?.resolve(true);
      this.model = false;
    }
  }
});
